/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
// import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { useMediaQuery } from '@material-ui/core';

export default (domain, env, path) => {
    const COLLECTION_BANNER_QUERY = gql`{findContent(brand: "${domain}", environment: "${env}", contentType: "collection_banners"){ content }}`;
    const { error, data } = useQuery(COLLECTION_BANNER_QUERY);
    const isDesktopDevice = useMediaQuery('(min-width: 600px)');

    if (error) return null;

    const bannerData = data?.findContent?.content?.entries;
    const gqlCollectionBannerData = {}; // Includes top/bottom banner and in-collection banners
    const bannerPageData = [];
    const pageMatch = () => {
        bannerData?.forEach((banner) => {
            if (banner.category_urls.filter((url) => url === path).length) {
                banner.banner.forEach((item) => {
                    bannerPageData.push(item);
                });
                gqlCollectionBannerData.imoc = banner.imoc;
            }
            return null;
        });
        if (bannerPageData.length) return true;
        return false;
    };
    if (bannerData && pageMatch()) {
        bannerPageData.map((banner) => {
            const pos = banner.positioning;
            const icbPositioning = isDesktopDevice ? pos.desktop : pos.mobile || pos.desktop;
            const icbSpots = isDesktopDevice ? pos.desktop.width : pos.mobile.width || pos.desktop.width;
            const icbImage = isDesktopDevice ? banner.banner_image : banner.banner_image_mobile || banner.banner_image;
            const icbVideo = banner.video || {};
            const icBanner = {
                image: icbImage,
                video: icbVideo,
                show_after_product_number: icbPositioning.position,
                number_of_spots_to_use: icbSpots,
                link: banner.banner_link.link,
                tracking_event_category: banner.banner_link.tracking_event_category,
                tracking_event_action: banner.banner_link.tracking_event_action,
                tracking_event_label: banner.banner_link.tracking_event_label,
                title: banner.title,
                presentation_type: banner.presentation_type,
                customer_quote: banner.customer_quote,
                customer_name: banner.customer_name,
                date_of_quote: banner.date_of_quote,
                customer_photo: banner.customer_photo,
                heading: banner.heading,
                description: banner.description,
            };
            gqlCollectionBannerData[parseInt(icbPositioning.position, 10) - 1] = icBanner;
            return null;
        });
    }
    return gqlCollectionBannerData;
};
