/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
    object, shape, string, bool, func, number,
} from 'prop-types';
import { compose } from 'recompose';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import track from 'react-tracking';
import mbpLogger from 'mbp-logger';
import loadable from '@loadable/component';

// helpers, redux, queries
import { withRouter } from 'react-router';
import qs from 'qs';
import { trackPage, trackCategoryData } from '../../../helpers/tracking/common/commonTrackingHelpers';
import { persistPage } from '../../../helpers/EnterpriseID/CustomerDataPersistance/customerDataPersistance';
import { getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import {
    getSSRDeviceType, getIsBot, getUserSubmittedProductFilterZipcode, getScrollPosition, country, getProductFilterOccasion,
} from '../../../../state/ducks/App/App-Selectors';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { setScrollPosition } from '../../../../state/ducks/App/App-Actions';
import { getActiveABTest } from '../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import GraphqlDisclaimer from './Partials/GraphqlDisclaimer/GraphqlDisclaimer';
import SimpleProductSkeleton from '../GraphqlSkeletonComponents/SimpleProductSkeleton';
import MobileDesktopFacetsV3 from './Partials/ProductFacetsV3/MobileDesktopFacetsV3';
import KlarnaPromotionBadge from '../../Checkout/KlarnaPaymentOnSiteMessage/KlarnaPromotionBadge/KlarnaPromotionBadge';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import FoodGuidedNav from './Partials/FoodGuidedNav/FoodGuidedNav';
import SelectedFilters from './Partials/SelectedFilters/SelectedFilters';
import GraphqlNoResultsContainer from '../GraphqlSearchPage/Partials/GraphqlNoResultsContainer';
import CollectionShippingMicrodata from '../../../helpers/Seo/CollectionShippingMicrodata';
import GenericSkeleton from '../GraphqlSkeletonComponents/GnavSkeleton';

// category buiilder ui components
const GraphqlLeftMenuContainer = loadable(() => import(/* webpackChunkName: "GraphqlLeftMenuContainer" */ './Partials/GraphqlLeftMenuContainer/GraphqlLeftMenuContainer'), {
    fallback: <GenericSkeleton />,
});
const RailMenuTabletContainer = loadable(() => import(/* webpackChunkName: "RailMenuTabletContainer" */ './Partials/RailMenuTabletContainer/RailMenuTabletContainer'));
const GraphqlProductContainer = loadable(() => import(/* webpackChunkName: "GraphqlProductContainer" */ './Partials/GraphqlProductContainer/GraphqlProductContainer'), {
    fallback: <SimpleProductSkeleton />,
});
const GraphqlAfterProductBanner = loadable(() => import(/* webpackChunkName: "GraphqlAfterProductBanner" */ './Partials/GraphqlAfterProductBanner/GraphqlAfterProductBanner'));

const GraphqlBreadcrumbs = loadable(() => import(/* webpackChunkName: "GraphqlBreadcrumbs" */ '../GraphqlCommonComponents/GraphqlBreadcrumbs/GraphqlBreadcrumbs'));
const GraphqlProductFacets = loadable(() => import(/* webpackChunkName: "GraphqlProductFacets" */ './Partials/GraphqlProductFacets/GraphqlProductFacets'));
const FaqBlock = loadable(() => import(/* webpackChunkName: "FaqBlock" */ './Partials/FaqBlock/FaqBlock'), {
    fallback: <GenericSkeleton />,
});
const SeoBlockContainer = loadable(() => import(/* webpackChunkName: "SeoBlock" */ '../../ContentTypeComponents/SeoBlock/SeoBlockContainer'));

const GraphqlSeoHeadTags = loadable(() => import(/* webpackChunkName: "GraphqlSeoHeadTags" */ '../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags'));
const GraphqlCategoryTopSection = loadable(() => import(/* webpackChunkName: "GraphqlCategoryTopSection" */ './Partials/GraphqlCategoryTopSection/GraphqlCategoryTopSection'), {
    fallback: <GenericSkeleton height="80px" />,
});
const FilterDialog = loadable(() => import(/* webpackChunkName: "FilterDialog" */ './Partials/FilterDialog/FilterDialog'), {
    resolveComponent: (components) => components.FilterDialog,
});
const styles = (theme) => ({
    leftMenu: {
        width: '100%',
        position: 'relative',
        [theme.breakpoints.up(599)]: {
            width: '31.5%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: '99',
        },
        [theme.breakpoints.up(769)]: {
            width: '260px',
            position: 'relative',
        },
        [theme.breakpoints.up(1025)]: {
            zIndex: '9',
        },
    },
    disableFacetLeftMenuTablet: {
        [theme.breakpoints.down(1025)]: {
            display: 'none',
        },
    },
    categoryContainer: {
        position: 'relative',
        marginTop: '10px',
    },
    fullWidthProductContainer: {
        width: '100%',
    },
    collection: {
        '& [class*="leftMenu"]:not([class*="disableFacetLeftMenuTablet"])~[id="productContainer"]': {
            [theme.breakpoints.up(769)]: {
                width: ' calc(100% - 290px)',
                marginLeft: '30px',
            },
        },
    },
    productContainer: {
        width: '100%',
        [theme.breakpoints.up(769)]: {
            width: ' calc(100% - 290px)',
            marginLeft: '30px',
        },
    },
    productContainerFood: {
        width: '100%',
        [theme.breakpoints.up(1025)]: {
            width: ' calc(100% - 290px)',
            marginLeft: '30px',
        },
    },
    categoryfooterContainer: {
        // justifyContent: 'center',
        padding: '35px 0 25px 0',
    },
    faqBlockContainer: {
        color: '#2f2f2f',
        margin: '40px auto',
        fontSize: '.8em',
        maxWidth: '980px',
        boxSizing: 'border-box',
    },
    faqFloralContainer: {
        margin: '30px auto',
        fontSize: '.8em',
        maxWidth: '1400px',
        boxSizing: 'border-box',
    },
    siteContainer: {
        margin: '0 15px',
        maxWidth: '1400px',
        [theme.breakpoints.up(1430)]: {
            margin: '0 auto',
        },
        [theme.breakpoints.up(700)]: {
            '& .nup_block': {
                '& h2': {
                    margin: '-10px 0 -5px 0 ',
                    fontFamily: 'PlayfairDisplayRegular, serif',
                    fontWeight: 'normal',
                    lineHeight: '1.2em',
                },
            },
        },
        [theme.breakpoints.down(336)]: {
            margin: '0 7px',
        },
        '@media only screen and (min-width: 768.5px)': {
            '& .nup_blockV3': {
                marginTop: '1em',
            },
        },
    },
    breadcrumbAndFilterContainer: {
        display: 'flex',
        alignItems: 'baseline',
        margin: '10px 0',
        flexWrap: 'wrap',
        [theme.breakpoints.down(599)]: {
            display: 'block',
        },
        '& $breadCrumb + $leftMenu': {
            [theme.breakpoints.up(769)]: {
                width: '100px',
            },
        },
    },
    breadcrumbAndFilterContainerFood: {
        margin: '15px 0',
        [theme.breakpoints.down(599)]: {
            margin: '10px 0 20px 0',
        },
    },
    backTop: {
        textAlign: 'center',
        marginBottom: '40px',
        '& img': {
            background: theme.palette.cms?.primary || theme.palette.colorPrimary,
            borderRadius: '50%',
            width: '35px',
            height: '34px',
            padding: '5px',
            display: 'block',
            margin: 'auto',
            marginBottom: '10px',
            cursor: 'pointer',
            '&:hover': {
                transform: 'translateY(-3px)',
                transition: 'all 0.5s ease',
            },
        },
        '& span': {
            textDecoration: 'underline',
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
            cursor: 'pointer',
        },
    },
    productFacetsScrolling: {
        height: '100%',
        display: 'block',
        position: 'relative',
        verticalAlign: 'top',
        [theme.breakpoints.down(1025)]: {
            display: 'none',
        },
    },
    productFacet: {
        marginBottom: 10,
        [theme.breakpoints.down(1025)]: {
            display: 'none',
        },
    },
    breadcrumbData: {
        margin: '15px auto 0px auto',
        maxWidth: '1400px',
        [theme.breakpoints.down(1430)]: {
            paddingLeft: '15px',
        },
        [theme.breakpoints.down(600)]: {
            margin: '15px auto 15px auto',
        },
    },
    withoutFilterCollection: {
        [theme.breakpoints.down(599)]: {
            margin: '15px 0 0 0',
        },
    },
    withoutFilterCollectionV3: {
        [theme.breakpoints.down(599)]: {
            margin: '13px 0 0 0',
        },
    },
    fliterFactsV2: {
        margin: '10px 0px',
        [theme.breakpoints.down(599)]: {
            width: '49%',
        },
        '&>span': {
            fontSize: 18,
            display: 'flex',
            alignItems: 'center',
            fontWeight: '600',
            fontStyle: 'normal',
            lineHeight: '16px',
            letterSpacing: '0.05em',
            position: 'relative',
            width: '190px',
            justifyContent: 'center',
            height: '42px',
            background: theme.palette.common?.white || '#ffffff',
            border: `1px solid ${theme.palette.upsellsTextColor || '#2F2F2F'}`,
            boxSizing: 'border-box',
            cursor: 'pointer',
            [theme.breakpoints.down(599)]: {
                width: '100%',
            },

        },
        '& svg': {
            marginLeft: 10,
            marginTop: 2,
            height: 18,
            width: 18,
        },
    },
    collapsedContainer: {
        '& .MuiPaper-elevation4': {
            boxShadow: 'none',
        },
    },
    mainFilterContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: '1px solid rgba(0, 0, 0, 0.25)',
        alignItems: 'center',
        [theme.breakpoints.down(1025)]: {
            padding: '20px 0px',
        },
    },
    filterCategoryName: {
        fontSize: 28,
        fontFamily: 'Garamond, Times, serif',
        [theme.breakpoints.down(1025)]: {
            fontSize: 32,
        },
        [theme.breakpoints.down(481)]: {
            fontSize: 30,
        },
    },
    catNameContainerV3: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '52px',
    },
    hideFilters: {
        border: '1px solid',
        borderRadius: '50%',
    },
    selectedFilterButton: {
        backgroundColor: `${theme.palette?.cms?.grey || '#f4f2f7'} !important`,
    },
    filterFacetsV3: {
        margin: '10px 0px',
        [theme.breakpoints.down(599)]: {
            width: '49%',
        },
        '&>span': {
            fontSize: 16,
            display: 'flex',
            alignItems: 'center',
            fontWeight: '600',
            fontStyle: 'normal',
            lineHeight: '16px',
            position: 'relative',
            justifyContent: 'space-between',
            height: '40px',
            border: `1px solid ${theme.palette.upsellsTextColor || '#2F2F2F'}`,
            boxSizing: 'border-box',
            cursor: 'pointer',
            [theme.breakpoints.down(599)]: {
                width: '100%',
            },
        },
        '& svg': {
            marginRight: 4,
            marginTop: 2,
            height: 27,
            width: 27,
        },
    },
    sortByLabelV3: {
        fontWeight: '700',
    },
    filterBtnText: {
        width: '100%',
        textAlign: 'center',
        fontSize: '16px',
    },
    hideFiltersBg: {
        backgroundColor: theme.palette.highlightColor || '#F5F5F5',
    },
    flowerSortV2Container: {
        justifyContent: 'flex-end',
    },
    mainFilterContainerV3: {
        gap: '16px',
        borderTop: '1px solid rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.down(1025)]: {
            padding: '20px 0px',
            display: 'flex',
        },
        [theme.breakpoints.up(1025)]: {
            display: 'flex',
            justifyContent: 'end',
            gridTemplateColumns: 'calc(12.5% - 16px) calc(75% - 16px) calc(12.5%)',
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: 'white',
        },
    },
    filterCategoryNameV3: {
        fontSize: 28,
        textAlign: 'center',
    },
    stickyFacetsContainer: {
        position: 'sticky',
        zIndex: 2,
        top: 0,
    },
    totalResults: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '18px',
        marginBottom: '10px',
    },
    boxShadowWrapper: {
        '& .MuiPaper-elevation4': {
            boxShadow: 'rgb(255 255 255) 5px -5px 0px 5px',
        },
        '& .MuiPaper-rounded': {
            borderRadius: '0px',
        },
    },
    noProductsText: {
        color: theme.palette.noProductsTextColor || '#EC0909',
        margin: '25px 0',
        fontSize: '14px',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    bestSellers: {
        color: theme.palette.cms?.black || '#000000',
    },
    breadCrumb: {
        display: 'inline-flex',
    },
    productCount: {
        marginLeft: '1.875rem',
        textAlign: 'right',
    },
    disableLeftSide: {
        '@media only screen and (max-width: 1024px) and (min-width: 768px)': {
            '& $leftMenu': {
                display: 'none',
            },
            '& $productContainer': {
                width: '100% !important',
                marginLeft: '0px !important',
            },
        },
    },
});

// Send persist tracked category data
class GraphqlCategoryBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterData: {},
            facetsData: this.getFacetsFromUrl(),
            openFacetDropdown: '',
            productCount: 0,
        };
    }

    componentDidMount() {
        const {
            scrollPosition, presentation_family, trackEventAction, history,
        } = this.props;

        // if user has scrolled to click on a product, remember that position when they navigate back
        if (scrollPosition.y >= 500 && scrollPosition.path === window?.location?.pathname && presentation_family === 'food') {
            setTimeout(() => { // give the page time to load some content
                window.scroll({
                    top: (scrollPosition.y + 100),
                    behavior: 'auto',
                });
            }, 70);
        }
        if (this.isFacetV3AbEnabled()) {
            trackEventAction({
                eventCategory: 'Test Impression',
                eventAction: 'Collection Page | Facets V3',
                eventLabel: `Variant - ${history?.location?.pathname || ''}`,
                nonInteraction: '1',
            });
        } else {
            trackEventAction({
                eventCategory: 'Test Impression',
                eventAction: 'Collection Page | Facets V3',
                eventLabel: 'Control',
                nonInteraction: '1',
            });
        }
    }

    getFacetsFromUrl = () => {
        const { history, featureFlags } = this.props;
        if (history.location?.search) {
            if (featureFlags['is-facet-throwing-error-resolver-enabled']) {
                try {
                    const decodedString = decodeURI(history?.location?.search).replace('?', '');
                    const queryParams = qs.parse(decodedString);
                    let redirectLocationData = queryParams.facet;
                    if (typeof queryParams.facet === 'string') {
                        redirectLocationData = [queryParams.facet];
                    }
                    const checkedFacetsData = redirectLocationData?.length && redirectLocationData?.[0] !== '' ? redirectLocationData : [];
                    return checkedFacetsData;
                } catch (e) {
                    mbpLogger.logError({
                        message: 'Malformed URI',
                        error: e,
                        module: 'useCheckedFacets',
                        function: 'useCheckedFacets',
                    });
                }
            } else if (history.location?.search) {
                const searchValue = unescape(history.location?.search);
                const locationData = searchValue?.includes('?facet=') ? searchValue.replace('?facet=', '') : '';
                const redirectLocationData = locationData?.split('&');
                const checkedFacetsData = redirectLocationData?.length && redirectLocationData?.[0] !== '' ? redirectLocationData : [];
                return checkedFacetsData;
            }
        }
        return [];
    }

    componentDidCatch(jsError, reactInfo) {
        const { categoryData } = this.props;
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: `Unhandled error in Graphql Category Builder \n jsError = ${jsError} \n reactInfo = ${reactInfo} \n categoryData = ${categoryData}`,
            categoryData,
        });
    }

    setFilterDataInCategoryBuilder = (data) => {
        this.setState({
            filterData: data,
        });
    }

    setFacetsDataInCollection = (facets, openFacetDropdown = '') => {
        this.setState({
            facetsData: facets,
            openFacetDropdown,
        });
    }

    setFacetLabel = (openFacetDropdown = '') => {
        this.setState({
            openFacetDropdown,
        });
    }

    setScrollPositionOnClick = (e) => {
        const { setCurrentScrollPosition } = this.props;
        const path = window.location.pathname;

        let isLoadMoreClick = false;
        if (e?.target) {
            if (e.target?.getAttribute?.('data-element') === 'loadMore'
                || e.target?.getAttribute?.('data-element') === 'icBanner'
                || e.target?.getAttribute?.('data-element') === 'persistNoScroll') {
                isLoadMoreClick = true;
            }
        }
        setCurrentScrollPosition({ y: isLoadMoreClick ? 0 : window.pageYOffset, path });
    };

    loadLeftMenuContainer = (isFacetFiltersEnabled, isFoodBrand, classes, productFacetsBase, isFacetFiltersCountEnabled, filterFlag, guidedNavFormData, showLeftRailContent, entriesData, isMobile, trackEventAction, isGuidedNavWidgetEnabled, contentGNavRef) => {
        const { categoryData } = this.props;
        return ((guidedNavFormData || contentGNavRef) || showLeftRailContent || filterFlag) ? (
            /* flowers brands render only */
            <GraphqlLeftMenuContainer
                contentGNavRef={contentGNavRef}
                guidedNavForm={guidedNavFormData}
                leftMenuData={entriesData}
                isMobile={isMobile}
                trackEvent={trackEventAction}
                // global guided nav widget flag
                isGuidedNavWidgetEnabledFlag={isGuidedNavWidgetEnabled}
                isFacetFiltersEnabled={isFacetFiltersEnabled}
                setFilterDataInCategoryBuilder={this.setFacetsDataInCollection}
                isFoodBrand={isFoodBrand}
                productFacetsBase={productFacetsBase}
                isFacetFiltersCountEnabled={isFacetFiltersCountEnabled}
                filterFlag={filterFlag}
                category={categoryData?.category || {}}
            />
        ) : (<></>);
    }

    loadProductFacets = (isFacetFiltersEnabled, isFoodBrand, classes, productFacetsBase, isFacetFiltersCountEnabled, filterFlag = true, category) => {
        const { trackEventAction } = this.props;
        return (isFacetFiltersEnabled && productFacetsBase?.length > 0) && filterFlag && (
            /* this is handling filter facets for food and floral */
            <div className={isFoodBrand
                ? classes.productFacetsScrolling
                : classes.productFacet}
            >
                <GraphqlProductFacets
                    category={category}
                    productFacets={productFacetsBase}
                    setFilterDataInCategoryBuilder={this.setFacetsDataInCollection}
                    isFoodBrand={isFoodBrand}
                    isFacetFiltersCountEnabled={isFacetFiltersCountEnabled}
                    track={trackEventAction}
                />
            </div>
        );
    }

    loadProductFacetsV3 = (isFoodBrand, productFacetsBase, isFacetFiltersCountEnabled, filterFlag, category, isFacetFiltersEnabled) => {
        const {
            classes, trackEventAction, categoryData, abCategorySortAndFilter, featureFlags, presentation_family,
        } = this.props;
        const { productCount } = this.state;
        return (
            <MobileDesktopFacetsV3
                isFoodBrand={isFoodBrand}
                classes={classes}
                productFacetsBase={productFacetsBase}
                isFacetFiltersCountEnabled={isFacetFiltersCountEnabled}
                filterFlag={filterFlag}
                category={category}
                trackEventAction={trackEventAction}
                categoryData={categoryData}
                abCategorySortAndFilter={abCategorySortAndFilter}
                isFacetFiltersEnabled={isFacetFiltersEnabled}
                featureFlags={featureFlags}
                setFacetsDataInCollection={this.setFacetsDataInCollection}
                setFilterDataInCategoryBuilder={this.setFilterDataInCategoryBuilder}
                presentation_family={presentation_family}
                openFacetDropdown={this.state.openFacetDropdown}
                setFacetLabel={this.setFacetLabel}
                productCount={productCount}
            />
        );
    }

    loadFilterDialog = (productFacetsBase, filterFlag, category, isFacetFiltersEnabled, leftRail) => {
        const {
            classes, trackEventAction, categoryData, featureFlags, presentation_family,
        } = this.props;
        const { productCount } = this.state;
        return (
            <FilterDialog
                cmsFilterFlag={categoryData?.content?.entries?.[0]?.show_filters}
                filterFlag={filterFlag}
                track={trackEventAction}
                featureFlags={featureFlags}
                setFacetsOptions={this.setFacetsDataInCollection}
                setFilterOptions={this.setFilterDataInCategoryBuilder}
                presentation_family={presentation_family}
                brand={categoryData?.brand}
                productFacets={productFacetsBase}
                isFacetFiltersEnabled={isFacetFiltersEnabled}
                category={category}
                styleLeftSidebar={classes.leftMenu}
                productCount={productCount}
                leftRail={leftRail}
            />
        );
    }

    pullProductCount = (count) => {
        this.setState({
            productCount: count,
        });
    };

    isFacetV3AbEnabled = () => {
        const { history, abCategorySpecificFacets } = this.props;
        if (history?.location?.pathname && abCategorySpecificFacets?.type === 'variant' && (abCategorySpecificFacets?.list?.includes?.(history?.location?.pathname) || abCategorySpecificFacets?.list?.includes?.('all'))) {
            return true;
        }
        return false;
    }

    @track(persistPage)
    @track(trackCategoryData)
    render() {
        const { filterData, facetsData } = this.state;
        const {
            classes,
            featureFlags,
            categoryData,
            page: {
                path,
            },
            presentation_family,
            ssrDeviceType,
            isBot,
            trackEventAction,
            setOccasion,
            collectionBannerData,
            collectionBannerV2Data,
            abCategorySortAndFilter,

            abGuidedNav,
            abNewGuidedNavFacelift,
            occasion,
            brand,
            history,
        } = this.props;
        const isFilterEnabled = featureFlags['is-sort-and-filter-collections-enabled'];
        const {
            guidedNavForm, category, content, samedayCutoff, subNavCollection,
        } = categoryData || null;
        // food brands
        const isMobile = ssrDeviceType === 'mobile';
        const isFoodBrand = presentation_family === 'food';

        // this var stores the facet categories (prince range, contents) and their entries (Under $30, Cheese) and tracks whether the facet category is expanded in the ui
        const productFacetsBase = category?.productFacets;

        if (!featureFlags['is-facet-filter-price-enabled']) {
            // removing new price filters if flag is off.
            const priceRange = (productFacetsBase || [])?.findIndex?.((value) => value?.name?.toLowerCase() === 'price range' && value?.entries?.filter?.((entry) => entry?.to > 0 || entry?.from > 0)?.length > 0);
            if (typeof productFacetsBase?.[priceRange] !== 'undefined') {
                delete productFacetsBase[priceRange];
            }
        }
        const [priceRangeData] = (productFacetsBase || [])?.filter((value) => value?.name?.toLowerCase() === 'price range');
        const isFacetFiltersEnabled = featureFlags['is-facet-filters-enabled'];
        const isFacetFiltersCountEnabled = featureFlags['is-filters-count-enabled'];
        const isGuidedNavWidgetEnabled = featureFlags['is-guided-nav-widget-enabled'];
        const hasMicrodata = featureFlags['has-collection-shipping-microdata'] || { content: false };
        // cms entries
        let entries = [];
        if (content && content.entries) {
            entries = content.entries;
        }
        const [entriesData] = entries || {};
        const { seo, related_articles_position, zip_code_unavailable_products } = entriesData || {};
        let { category_blocks } = entriesData || [];
        if (typeof category_blocks === 'undefined') {
            category_blocks = [];
        }

        let guidedNavFormData = guidedNavForm?.entries?.[0];

        const isUpdatedGnav = featureFlags['is-updated-gnav-presentation'] && abNewGuidedNavFacelift === 'variant';

        if (isUpdatedGnav) {
            guidedNavFormData = guidedNavForm?.entries?.find((entry) => entry.title === 'GnavV3') || {};
        } else if (!isUpdatedGnav && guidedNavForm?.entries?.length > 1) {
            guidedNavFormData = guidedNavForm?.entries?.find(((entry) => entry.title === 'Default')) || {};
        }
        const contentGNavRef = entries?.[0]?.guided_nav?.guided_nav_form_reference?.[0] || {};
        const occasionCode = entries?.[0]?.occasion_code;
        const occasionName = entries?.[0]?.name;
        if (occasionName && occasionCode && (occasion.name !== occasionName && occasion.code !== occasionCode)) {
            setOccasion(occasionName, occasionCode, occasionCode);
        }
        // if exsists will render to page
        let disclaimerBlock;
        let bannerBlock;
        let faqBlock;
        let bannerPositionTop;

        category_blocks.forEach((block) => {
            if (block.banner) {
                bannerBlock = block.banner;
                bannerPositionTop = bannerBlock?.position === 'Top';
            }
            if (block.disclaimer) disclaimerBlock = block.disclaimer;
            if (block.faq) faqBlock = block;
        });

        // Determine if the IMOCS are defined as blocks in the "In Collection Banner" content type.
        // prop will be renamed "inCollectionBannerData" to reflect new usage in GraphqlProductContainer
        if (collectionBannerData && Object.keys(collectionBannerData).length) {
            if (collectionBannerData?.imoc?.position === 'Top') bannerPositionTop = true;
            bannerBlock = collectionBannerData?.imoc;
        }

        // true if we have related_articles || block.related_searches || block.side_rail_banner
        const showLeftRailContent = category_blocks.some((block) => ((block.related_articles || block.related_searches || block.side_rail_banner)));
        const breadcrumbGridData = () => (seo?.breadcrumb && <GraphqlBreadcrumbs breadCrumbArray={seo.breadcrumb} />);

        const filterFlag = isFacetFiltersEnabled;
        const { page } = this.props;
        const isContentGNavRef = Object.keys(contentGNavRef).length > 0;
        const leftRail = ((((isContentGNavRef && entriesData?.guided_nav?.show_guided_nav) || guidedNavFormData) && abGuidedNav !== 'hide' && isGuidedNavWidgetEnabled) || showLeftRailContent || (isFacetFiltersEnabled && productFacetsBase?.length > 0 && history?.location?.pathname !== '/flowers-gifts-400198087'));

        const disableLeftSide = !((((isContentGNavRef && entriesData?.guided_nav?.show_guided_nav) || guidedNavFormData) && abGuidedNav !== 'hide' && isGuidedNavWidgetEnabled) || showLeftRailContent);

        /**
         * If category is empty or no data return for specific category URL.
         */
        if (entries?.length === 0 && !categoryData?.id) {
            const noProductTexts = 'There are currently no products available in this collection. Try your search again using the search box at the top of the page.';
            mbpLogger.logDebug({
                message: `PATH: ${path} - Current Brand: ${brand?.domain} - Category Empty/Null`,
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
            });

            return (
                <>
                    <div className={classes.noProductsText}>
                        {noProductTexts}
                        <h1 className={classes.bestSellers}>Best Sellers</h1>
                        <GraphqlNoResultsContainer brand={brand} path={path} />
                    </div>
                </>
            );
        }

        return (
            <div id="category-page-container" data-pagetype="category" className={classes.collection}>
                {hasMicrodata.content && CollectionShippingMicrodata({ page, hasMicrodata })}
                <GraphqlSeoHeadTags seoData={seo} />
                <div className={classes.breadcrumbData}>{breadcrumbGridData()}</div>
                <div className={classes.siteContainer}>
                    <GraphqlCategoryTopSection
                        category_blocks={category_blocks}
                        subNavCollection={subNavCollection}
                        brandName={categoryData?.brand}
                        samedayCutoff={samedayCutoff}
                        featureFlags={featureFlags}
                        trackEventAction={trackEventAction}
                        bannerPositionTop={bannerPositionTop}
                        ssrDeviceType={ssrDeviceType}
                        isFoodBrand={isFoodBrand}
                    />

                    {/* V1 all viewports food / V1 tablet and up for floral brands */}

                    <Media
                        queries={{
                            small: '(max-width: 598px)',
                            medium: '(min-width: 599px) and (max-width: 1024px)',
                            large: '(min-width: 1025px)',
                        }}
                        defaultMatches={{ small: ssrDeviceType === 'mobile', medium: ssrDeviceType === 'desktop', large: ssrDeviceType === 'desktop' }}
                    >
                        {(matches) => (
                            <>
                                {(matches.small && isFoodBrand) && this.loadProductFacetsV3(isFoodBrand, productFacetsBase, isFacetFiltersCountEnabled, filterFlag, category, isFacetFiltersEnabled)}
                                {matches.medium && this.loadProductFacetsV3(isFoodBrand, productFacetsBase, isFacetFiltersCountEnabled, filterFlag, category, isFacetFiltersEnabled)}
                                {matches.large &&  (
                                    <div className={`${classes.breadcrumbAndFilterContainer} ${isFoodBrand ? classes.breadcrumbAndFilterContainerFood : ''}`}>
                                        {category !== null && abCategorySortAndFilter !== 'hide'
                                            && (
                                                this.loadFilterDialog(productFacetsBase, filterFlag, category, isFacetFiltersEnabled, leftRail)
                                            )}
                                    </div>
                                )}
                            </>
                        )}
                    </Media>

                    {/*
                                ___________________________________________________
                                ___________________________________________________
                            |                    |                              |
                            |                    |                              |
                            |    guidedNavForm   |                              |
                            |      -AND/OR-      |                              |
                            |  related_articles  |                              |
                            |  related_searches  |   GraphqlProductContainer    |
                            |  side_rail_banner  |                              |
                            |        -OR-        |                              |
                            |    FacetFilters    |                              |
                            |                    |                              |
                            |                    |                              |
                                ___________________________________________________
                            // TODO: make conditions into variables to reuse - mike
                    */}

                    {entriesData?.guided_nav?.show_guided_nav && featureFlags['is-gnav-v2-enabled'] && <FoodGuidedNav />}
                    <Grid container className={`${classes.categoryContainer} ${disableLeftSide && !isFoodBrand ? classes.disableLeftSide : ''}`}>
                        {(isFacetFiltersEnabled && entriesData?.guided_nav?.show_guided_nav && abGuidedNav !== 'hide' && isGuidedNavWidgetEnabled) || showLeftRailContent || (isFacetFiltersEnabled && history?.location?.pathname !== '/flowers-gifts-400198087') ? (
                            <Grid item className={`${classes.leftMenu} ${isFacetFiltersEnabled && isFoodBrand ? classes.disableFacetLeftMenuTablet : ''}`}>
                                {(isFoodBrand) ? (
                                    <>
                                        {related_articles_position === 'below' ? (
                                            <>
                                                {this.loadProductFacets(isFacetFiltersEnabled, isFoodBrand, classes, productFacetsBase, isFacetFiltersCountEnabled, filterFlag, category)}
                                                {this.loadLeftMenuContainer(guidedNavFormData, showLeftRailContent, entriesData, isMobile, trackEventAction, isGuidedNavWidgetEnabled, contentGNavRef)}
                                            </>
                                        ) : (
                                            <>
                                                {this.loadLeftMenuContainer(guidedNavFormData, showLeftRailContent, entriesData, isMobile, trackEventAction, isGuidedNavWidgetEnabled, contentGNavRef)}
                                                {this.loadProductFacets(isFacetFiltersEnabled, isFoodBrand, classes, productFacetsBase, isFacetFiltersCountEnabled, filterFlag, category)}
                                            </>
                                        )}
                                    </>
                                ) : this.loadLeftMenuContainer(isFacetFiltersEnabled, isFoodBrand, classes, productFacetsBase, isFacetFiltersCountEnabled, filterFlag, guidedNavFormData, showLeftRailContent, entriesData, isMobile, trackEventAction, isGuidedNavWidgetEnabled, contentGNavRef)}
                                {!isFoodBrand && (
                                    <Media
                                        query="(max-width:598px)"
                                        render={() => (
                                            <>
                                                {this.loadProductFacetsV3(isFoodBrand, productFacetsBase, isFacetFiltersCountEnabled, filterFlag, category, isFacetFiltersEnabled)}
                                            </>
                                        )}
                                    />
                                )}
                            </Grid>
                        ) : null}
                        <Grid
                            item
                            className={`${leftRail
                                && (
                                    isFoodBrand
                                        ? classes.productContainerFood
                                        : classes.productContainer
                                )} ${classes.fullWidthProductContainer}
                                            )${!isFilterEnabled && (classes.withoutFilterCollectionV3)}`}
                            id="productContainer"
                            onClick={this.setScrollPositionOnClick}
                        >
                            {featureFlags['is-facets-v1-chips-enabled'] && isFacetFiltersEnabled && (
                                <SelectedFilters
                                    productFacetsBase={productFacetsBase}
                                    setFacetsDataInCollection={this.setFacetsDataInCollection}
                                    facetsData={facetsData}
                                    history={history}
                                />
                            )}
                            <GraphqlProductContainer
                                path={path}
                                filterData={filterData}
                                facetsData={facetsData}
                                categoryData={categoryData}
                                breadCrumbArray={seo?.breadcrumb}
                                skipAddons={entriesData?.skip_add_ons === true}
                                showMovieUpsell={entriesData?.show_movie_download_upsell && entriesData?.movie_download_upsell ? entriesData.movie_download_upsell : false}
                                movieData={entriesData?.movie_download_upsell}
                                inCollectionBannerData={collectionBannerData}
                                inCollectionBannerV2Data={collectionBannerV2Data}
                                page={page}
                                priceRangeData={priceRangeData}
                                zipCodeUnavailableProducts={zip_code_unavailable_products}
                                leftRail={leftRail}
                                isDesktopFacetsV3={isFacetFiltersEnabled}
                                pullProductCount={this.pullProductCount}
                                isGnavEnabled={entriesData?.guided_nav?.show_guided_nav}
                                isCategoryPage
                            />
                        </Grid>
                    </Grid>
                    {/* render only if data */}
                    {(bannerBlock && !bannerPositionTop) && (
                        <Grid>
                            <GraphqlAfterProductBanner bannerBlock={bannerBlock} trackEvent={trackEventAction} device={ssrDeviceType} />
                        </Grid>
                    )}
                    {/* render only if data */}
                    {disclaimerBlock?.copy && (!isMobile || isBot) ? (
                        // render on screens >= 769
                        <Grid container className={classes.categoryfooterContainer}>
                            <GraphqlDisclaimer disclaimerBlock={disclaimerBlock} />
                        </Grid>
                    ) : null}

                </div>
                {(entriesData) ? (
                    <RailMenuTabletContainer
                        railMenuData={entriesData}
                        isMobile={isMobile}
                    />
                ) : null}
                {categoryData?.content?.entries?.[0]?.seo_copy && <SeoBlockContainer data={categoryData.content.entries[0].seo_copy} />}
                {faqBlock && (!isMobile || isBot) ? (
                    // render on screens >= 769
                    <div className={presentation_family === 'flower' ? classes.faqFloralContainer : classes.faqBlockContainer}>
                        <FaqBlock faqBlock={faqBlock} />
                    </div>
                ) : null}

                <KlarnaPromotionBadge />
            </div>
        );
    }
}

GraphqlCategoryBuilder.propTypes = {
    classes: object.isRequired,
    categoryData: shape({
        content: object.isRequired,
        brand: string.isRequired,
        category: object.isRequired,
        guidedNavForm: object,
    }).isRequired,
    page: shape({
        path: string,
    }),
    featureFlags: object.isRequired,
    presentation_family: string.isRequired,
    ssrDeviceType: string,
    isBot: bool.isRequired,
    trackEventAction: func.isRequired,
    setOccasion: func.isRequired,
    setCurrentScrollPosition: func.isRequired,
    scrollPosition: shape({
        y: number,
        path: string,
    }),
    abCategorySortAndFilter: string,
    abGuidedNav: string,
    collectionBannerData: shape({
        image: object,
        show_after_product_number: number,
        number_of_spots_to_use: number,
        link: object,
        tracking_event_category: string,
        tracking_event_action: string,
        tracking_event_label: string,
    }).isRequired,
    collectionBannerV2Data: shape({
        image: object,
        show_after_product_number: number,
        number_of_spots_to_use: number,
        link: object,
        tracking_event_category: string,
        tracking_event_action: string,
        tracking_event_label: string,
    }).isRequired,
    occasion: shape({
        name: string,
        code: string,
    }),
    brand: shape({
        code: string.isRequired,
    }).isRequired,
    history: shape({
        location: shape({
            search: string.isRequired,
        }).isRequired,
    }).isRequired,
    abCategorySpecificFacets: string,
    abNewGuidedNavFacelift: string,
};

GraphqlCategoryBuilder.defaultProps = {
    page: shape({
        path: '',
    }),
    ssrDeviceType: '',
    scrollPosition: {},
    abCategorySortAndFilter: '',
    abGuidedNav: '',
    abNewGuidedNavFacelift: 'control',
    occasion: {},
    abCategorySpecificFacets: '',
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    ssrDeviceType: getSSRDeviceType(state),
    isBot: getIsBot(state),
    userSubmittedZip: getUserSubmittedProductFilterZipcode(state),
    scrollPosition: getScrollPosition(state),
    countryName: country(state), // Used for tracking recently viewed products
    abNewGuidedNavFacelift: getActiveABTest('new_guided_nav_facelift')(state),
    abCategorySortAndFilter: getActiveABTest('category_sort_and_filter')(state),
    abCategoryFilters: getActiveABTest('category_filters')(state),
    abGuidedNav: getActiveABTest('guided-nav')(state),
    occasion: getProductFilterOccasion(state),
    brand: getBrand(state),
    abCategorySpecificFacets: getActiveABTest('category_specific_filter')(state),
});

const mapDispatchToProps = (dispatch) => ({
    trackEventAction: bindActionCreators(trackEvent, dispatch),
    setCurrentScrollPosition: bindActionCreators(setScrollPosition, dispatch),
});

const enhance = compose(
    trackPage(),
    withStyles(styles),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(GraphqlCategoryBuilder);
