/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
// libraries
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useQuery } from '@apollo/client';
import {
    object, string,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import loadable from '@loadable/component';

// components
import Media from 'react-media';
import GraphqlCategorySkeleton from '../GraphqlSkeletonComponents/CategoryPageSkeleton/CategoryPageSkeleton';
import GraphqlPassportCategoryPageContainer from './GraphqlPassportCategoryPageContainer';

// helpers, redux, queries
import {
    getSSRDeviceType,
} from '../../../../state/ducks/App/App-Selectors';
import { getPresentationFamily } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { GRAPHQL_ENV } from '../../../gql';
import { coreBrowsingPageViewSuccessTest } from '../../../helpers/tracking/common/commonTrackingHelpers';
import withPageView from '../../../helpers/tracking/hocs/withPageView';
import { findCategoryPageByUrlNoProducts } from '../../../gql/queries/findCategoryPageByURL';
import useExperimentServiceAttributes from '../../../helpers/experimentService/useExperimentServiceAttributes';

const UnderNavTextWidgetContainer = loadable(() => import(/* webpackChunkName: "UnderNavTextWidgetContainer" */ '../GraphqlCommonComponents/GraphqlUnderNavTextWidget/GraphqlUnderNavTextWidgetContainer'));

// memoized to prevent re-renders, stopReRender ^
const GraphqlCategoryPageContainer = ({
    brand, page, presentation_family, ssrDeviceType,
}) => {
    const { targeting, context, isGraphqlTargetingEnabled  } = useExperimentServiceAttributes({ queryName: 'findCategoryPageByUrl' });
    const CATEGORY_PAGE_QUERY = findCategoryPageByUrlNoProducts(isGraphqlTargetingEnabled);
    const {
        loading, data, error,
    } = useQuery(CATEGORY_PAGE_QUERY, {
        variables: {
            brand: brand.domain,
            url: page.path,
            contentType: page.contentType,
            environment: GRAPHQL_ENV,
            locale: 'en-us',
            ...(isGraphqlTargetingEnabled ? { targeting } : {}),
        },
        context,
    });
    if (loading) {
        return <GraphqlCategorySkeleton />;
    }

    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'GraphqlCategoryPageContainer.js',
            message: `Error loading findCategoryPageByURL from url "${page.path}"`,
            env: GRAPHQL_ENV,
            error,
        });
        return null;
    }
    if (!data || !data?.findCategoryPageByUrl?.content || !data?.findCategoryPageByUrl?.category?.products?.length) {
        if (!data) {
            mbpLogger.logWarning({
                appName: process.env.npm_package_name,
                component: 'GraphqlCategoryPageContainer.js',
                message: `No data for findCategoryPageByURL from url "${page.path}"`,
                env: GRAPHQL_ENV,
            });
        }
        if (!data?.findCategoryPageByUrl?.category?.products?.length) {
            mbpLogger.logWarning({
                appName: process.env.npm_package_name,
                component: 'GraphqlCategoryPageContainer.js',
                message: `No products returned for findCategoryPageByURL from url "${page.path}"`,
                env: GRAPHQL_ENV,
            });
        }
        if (!data?.findCategoryPageByUrl?.content) {
            mbpLogger.logWarning({
                appName: process.env.npm_package_name,
                component: 'GraphqlCategoryPageContainer.js',
                message: `No category content returned for findCategoryPageByURL from url "${page.path}"`,
                env: GRAPHQL_ENV,
            });
        }
    }

    return (
        <>
            {data?.findCategoryPageByUrl?.showUnderNavText && (
                // renders only if screensize is >= 1025px
                // add min func to desktop-mobile
                <Media
                    query="(min-width: 1025px)"
                    defaultMatches={ssrDeviceType !== 'mobile'}
                    render={() => (
                        <UnderNavTextWidgetContainer page={page} isCanadianCategory={data.findCategoryPageByUrl.content?.entries?.[0]?.country === 'Canada'} />
                    )}
                />
            )}
            <GraphqlPassportCategoryPageContainer
                page={page}
                categoryData={data?.findCategoryPageByUrl || {}}
                brand={data?.findCategoryPageByUrl?.brand}
                presentation_family={presentation_family}
                deviceType={ssrDeviceType}
            />
        </>
    );
};

GraphqlCategoryPageContainer.propTypes = {
    brand: object.isRequired,
    page: object.isRequired,
    presentation_family: string.isRequired,
    ssrDeviceType: object.isRequired,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
    presentation_family: getPresentationFamily(state),
    ssrDeviceType: getSSRDeviceType(state),
});

const enhance = compose(
    withPageView({
        pageViewSuccessTest: coreBrowsingPageViewSuccessTest,
        updateEveryRender: true,
    }),
    withRouter,
    connect(mapStateToProps),
);

export default enhance(GraphqlCategoryPageContainer);
