/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
    getValidatedZipcode,
    getIsProductFilterZipcodeValid,
} from '../../../../../../state/ducks/App/App-Selectors';
import {
    clearValidatedZipcode,
    validateProductFilterZipcode,
    resetProductFilterZipcodeValidity,
    clearUserSubmittedProductFilterZipcode,
    setUserSubmittedProductFilterZipcode,
} from '../../../../../../state/ducks/App/App-Actions';

const useStyles = makeStyles((theme) => ({
    chipError: {
        backgroundColor: '#F9D1D1', // reddish error color
        padding: '5px 5px 5px 0px',
        borderRadius: '20px',
        fontSize: '12px',
        fontFamily: 'lato, Arial, Verdana, sans-serif',
        whiteSpace: 'nowrap',
        color: '#2f2f2f',
    },
    chipFocused: {
        backgroundColor: 'rgb(206, 206, 206)', // default grey color
        padding: '5px 5px 5px 0px',
        borderRadius: '20px',
        fontSize: '12px',
        fontFamily: 'lato, Arial, Verdana, sans-serif',
        whiteSpace: 'nowrap',
        color: '#2f2f2f',
    },
    chip: {
        backgroundColor: theme.palette.highlightColor || '#F5F5F5', // success
        padding: '5px 5px 5px 0px',
        borderRadius: '20px',
        fontSize: '12px',
        fontFamily: 'lato, Arial, Verdana, sans-serif',
        whiteSpace: 'nowrap',
        color: '#2f2f2f',
    },
    chipCloseBtn: {
        border: 'none',
        padding: 0,
        margin: 0,
        backgroundColor: 'transparent',
        lineHeight: 1,
    },
    input: {
        fontSize: '12px',
        background: 'none',
        border: 0,
        borderBottom: '1px solid #aaaaaa',
        width: '40px',
        outline: 'none',
        color: '#2f2f2f',
        padding: '0px',
        borderRadius: '0px',
    },
    displayInline: {
        display: 'inline-block',
    },
    container: {
        '@media screen and (max-width: 600px)': {
            marginBottom: '15px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
        },
    },
}));

const FoodGuidedNav = () => {
    const isCanadaZip = (zipcode) => new RegExp(/^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[- ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}/).test(zipcode); // Canada zip code
    const classes = useStyles();
    const dispatch = useDispatch();
    const zipcodeRedux = useSelector((state) => getValidatedZipcode(state));
    const zipValid = useSelector((state) => getIsProductFilterZipcodeValid(state));
    const [zipCode, setZipCode] = useState(zipcodeRedux);
    const [zipCodeSubmission, setZipCodeSubmission] = useState(false);
    const [error, setError] = useState(false); // null for focused/visited

    useEffect(() => {
        if (zipValid && zipCodeSubmission) {
            setError(false);
        } else if (zipcodeRedux !== zipCode || !zipValid) {
            setError(true);
        }
    }, [zipValid]);

    const validateUserSubmittedZipcode = (value) => {
        let userSubmittedZipCode = value;
        if ((userSubmittedZipCode.length === 6 || userSubmittedZipCode.length === 7) && isCanadaZip(userSubmittedZipCode)) {
            // if user enters 6 or 7 chars and is canada zipcode
            const zipSplit = userSubmittedZipCode.replace(/\s/gi, '').toUpperCase().split('');
            zipSplit.splice(3, 0, ' ');
            const canadaZipCode = zipSplit.join('');
            userSubmittedZipCode = canadaZipCode;
            dispatch(validateProductFilterZipcode(canadaZipCode));
        } else if (userSubmittedZipCode.length === 5 && /^\d+$/.test(zipCode) === true) {
            dispatch(validateProductFilterZipcode(userSubmittedZipCode));
        } else if (userSubmittedZipCode.length !== 5 && zipValid !== null) {
            dispatch(resetProductFilterZipcodeValidity());
        }
    };

    useEffect(() => {
        if (zipCodeSubmission && !error && zipValid) {
            dispatch(setUserSubmittedProductFilterZipcode(zipCode?.trimEnd()));
            setError(false);
        } else if (zipCodeSubmission) {
            setError(true);
        }
    }, [zipCodeSubmission]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setZipCodeSubmission(true);
    };

    const ChipLabel = () => (
        <>
            <span className={classes.labelZip}>Zip Code: </span>
            <form data-testid="food-guided-nav-form" className={classes.displayInline} onSubmit={handleSubmit}>
                <input
                    className={classes.input}
                    type="text"
                    maxLength={7}
                    data-testid="food-guided-nav"
                    value={zipCode}
                    onChange={(e) => {
                        validateUserSubmittedZipcode(e?.target?.value || '');
                        setZipCode(e?.target?.value || '');
                        setError(null);
                        setZipCodeSubmission(false);
                    }}
                />
            </form>
        </>
    );

    if (!zipcodeRedux && !zipCode) {
        return <></>;
    }

    let chipClass = classes.chip;
    if (error === null) {
        chipClass = classes.chipFocused;
    } else if (error) {
        chipClass = classes.chipError;
    }

    return (
        <div className={classes.container}>
            <Chip
                label={ChipLabel()}
                onDelete={() => {
                    setZipCode(null);
                    dispatch(setUserSubmittedProductFilterZipcode(''));
                    dispatch(clearUserSubmittedProductFilterZipcode());
                    dispatch(clearValidatedZipcode());
                    dispatch(resetProductFilterZipcodeValidity());
                }}
                className={chipClass}
            />
        </div>
    );
};

export default FoodGuidedNav;
