/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
// libraries
import React from 'react';
import mbpLogger from 'mbp-logger';
import { useQuery } from '@apollo/client';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    array,
    arrayOf,
    func, object, shape, string,
} from 'prop-types';
import { GRAPHQL_ENV } from '../../../gql';
import findProductPageByPartNumber from '../../../gql/queries/findProductPageByPartNumber';
import GraphqlCategorySkeleton from '../GraphqlSkeletonComponents/CategoryPageSkeleton/CategoryPageSkeleton';
import GraphqlCategoryBuilder from './GraphqlCategoryBuilder';
import { setPassportBundleCollectionData } from '../../../../state/ducks/Passport/Passport-Action';
import getCollectionBannerData from './Partials/getCollectionBannerData/getCollectionBannerData';
import getCollectionBannerV2Data from './Partials/getCollectionBannerData/getCollectionBannerV2Data';
import { setProductFilterOccasion } from '../../../../state/ducks/App/App-Actions';
import SisterBrandProduct from './SisterBrandProducts/SisterBrandProducts';

const GraphqlPassportCategoryPageContainer = ({
    page, categoryData,
    brand, presentation_family,
    passportBundleCollection, setOccasion,
    location,
}) => {
    const categoryBlocks = categoryData?.content?.entries?.[0]?.category_blocks;
    let passportSkuDetails = {};
    let passportDetails = {};
    (categoryBlocks || []).forEach((block) => {
        if (block.passport_bundle_product) {
            passportDetails = block.passport_bundle_product;
            passportSkuDetails = block.passport_bundle_product?.passport_referenced_sku?.[0];
        }
    });

    const collectionBannerData = getCollectionBannerData(brand, GRAPHQL_ENV, page.path);
    const collectionBannerV2Data = getCollectionBannerV2Data(brand, GRAPHQL_ENV, page.path);

    if (passportSkuDetails?.passport_item_sku && passportSkuDetails?.item_sku && passportSkuDetails?.price_type) {
        const PRODUCT_BY_PART = findProductPageByPartNumber(brand, passportSkuDetails?.item_sku);
        const { loading, error, data } = useQuery(PRODUCT_BY_PART);

        if (loading) {
            return <GraphqlCategorySkeleton />;
        }

        if (error) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                component: 'GraphqlPassportCategoryPageContainer.js',
                message: `Error loading findProductPageByPartNumber from url "${page?.path}" passportPartNumber "${passportSkuDetails?.item_sku}"`,
                env: GRAPHQL_ENV,
                error,
            });
        }

        if (!data) {
            mbpLogger.logWarning({
                appName: process.env.npm_package_name,
                query: PRODUCT_BY_PART,
                component: 'GraphqlPassportCategoryPageContainer.js',
                message: `No data returned passportSKU "${passportSkuDetails?.item_sku}"`,
                env: GRAPHQL_ENV,
            });
        }

        if (data?.findProductPageByPartNumber?.product?.productSkus.length > 0) {
            const passportSkus = data?.findProductPageByPartNumber?.product?.productSkus || [];
            let collectionDetail = {};
            passportSkus.forEach((sku) => {
                if (sku?.id === passportSkuDetails?.passport_item_sku) {
                    const prices = sku?.prices || [];
                    prices.forEach((selectedSku) => {
                        if (selectedSku?.type === passportSkuDetails?.price_type) {
                            collectionDetail = {
                                enabled: true,
                                price: selectedSku?.value,
                                snipeImage: passportDetails?.snipe_image?.url,
                            };
                        }
                    });
                }
            });
            passportBundleCollection(collectionDetail);
        }
    } else {
        passportBundleCollection({ enabled: false });
    }

    let catData = categoryData;
    if (categoryData) {
        /**
         * New apollo version not allow to updating the object.
         */
        catData = JSON.parse(JSON.stringify(categoryData));
    }
    /**
    * This is using fo GA events
    */
    if (categoryData.category && !categoryData.category?.name) {
        catData.category.name = categoryData?.content?.entries?.[0]?.name || '';
    }

    return (
        <SisterBrandProduct categoryId={categoryData?.category?.id || ''}>
            <GraphqlCategoryBuilder
                location={location}
                page={page}
                categoryData={catData}
                brand={brand}
                presentation_family={presentation_family}
                setOccasion={setOccasion}
                collectionBannerData={collectionBannerData}
                collectionBannerV2Data={collectionBannerV2Data}
            />
        </SisterBrandProduct>
    );
};

GraphqlPassportCategoryPageContainer.propTypes = {
    location: object.isRequired,
    page: shape({
        path: string,
    }),
    categoryData: shape({
        content: shape({
            entries: arrayOf(shape({
                category_blocks: array,
                guided_nav: object,
            })),
        }),
    }),
    brand: string,
    presentation_family: string,
    passportBundleCollection: func,
    setOccasion: func,
};

GraphqlPassportCategoryPageContainer.defaultProps = {
    page: {},
    categoryData: {
        content: {
            entries: [
                {
                    category_blocks: [],
                    guided_nav: {},
                },
            ],
        },
    },
    brand: '',
    presentation_family: '',
    passportBundleCollection: () => {},
    setOccasion: () => {},
};

const mapDispatchTpProps = (dispatch) => ({
    passportBundleCollection: bindActionCreators(setPassportBundleCollectionData, dispatch),
    setOccasion: bindActionCreators(setProductFilterOccasion, dispatch),
});

export default connect(null, mapDispatchTpProps)(GraphqlPassportCategoryPageContainer);
