/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
// import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { useMediaQuery } from '@material-ui/core';

export default (domain, env, path) => {
    const COLLECTION_BANNER_QUERY = gql`{findContent(brand: "${domain}", environment: "${env}", contentType: "incollection_banner_v2"){ content }}`;
    const { error, data } = useQuery(COLLECTION_BANNER_QUERY);
    const isDesktopDevice = useMediaQuery('(min-width: 600px)');

    if (error) return null;

    const bannerData = data?.findContent?.content?.entries;
    const gqlCollectionBannerData = {}; // Includes top/bottom banner and in-collection banners
    const bannerPageData = [];
    const pageMatch = () => {
        bannerData?.forEach((banner) => {
            if (banner.category_urls.filter((url) => url === path).length) {
                banner.banners.forEach((item) => {
                    if (item.subcollection?.reference?.length) bannerPageData.push(item?.subcollection);
                });
            }
            return null;
        });
        if (bannerPageData.length) return true;
        return false;
    };
    if (bannerData && pageMatch()) {
        bannerPageData.map((banner) => {
            const icbPositioning = isDesktopDevice ? banner.position_desktop : banner.position_mobile || banner.position_desktop;
            gqlCollectionBannerData[icbPositioning] = banner;
        });
    }
    return gqlCollectionBannerData;
};
