/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SkeletonStyles from './SkeletonStyles';

const styles = (theme) => ({
    // Import Skeleton layout
    ...SkeletonStyles,

    // Page Elements
    productGrid: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        gridGap: '5px 20px',
        [theme.breakpoints.down(600)]: {
            gridTemplateColumns: 'auto auto',
        },
        '&--product-block': {
            paddingBottom: '109.565217%',
        },
        '&--product-title': {
            width: '40%',
        },
        '&--product-price': {
            width: '22%',
        },
        '&--product-delivery': {
            width: '31%',
            marginBottom: '20px',
        },
    },
});

const SimpleProductSkeleton = ({ classes }) => (
    <div>
        <div className={classes.productGrid}>
            <div>
                <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
            </div>
            <div>
                <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
            </div>
            <div>
                <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
            </div>
            <div>
                <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
            </div>
            <div>
                <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
            </div>
            <div>
                <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
            </div>
            <div>
                <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
            </div>
            <div>
                <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
            </div>
            <div>
                <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
            </div>
        </div>
    </div>
);

SimpleProductSkeleton.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(SimpleProductSkeleton);
