/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    shape, string, bool, oneOfType,
} from 'prop-types';

const CollectionShippingMicrodata = ({ page, hasMicrodata }) => {
    if (!page?.path) return <></>;
    if (!hasMicrodata?.pathmatch) return <></>;
    if (page.path.match(hasMicrodata.pathmatch)?.length) {
        return (
            <div itemscope="" itemtype="http://schema.org/CollectionPage">
                <meta itemprop="name" content={hasMicrodata.content} />
                <div itemprop="offers" itemscope="" itemtype="http://schema.org/AggregateOffer">
                    <meta itemprop="priceCurrency" content="USD" />
                    <div itemprop="shippingDetails" itemscope="" itemtype="http://schema.org/OfferShippingDetails">
                        <div itemprop="deliveryTime" itemscope="" itemtype="http://schema.org/ShippingDeliveryTime">
                            <div itemprop="handlingTime" itemscope="" itemtype="http://schema.org/QuantitativeValue">
                                <meta itemprop="minValue" content="0" />
                                <meta itemprop="maxValue" content="1" />
                                <meta itemprop="unitCode" content="DAY" />
                            </div>
                            <div itemprop="transitTime" itemscope="" itemtype="http://schema.org/QuantitativeValue">
                                <meta itemprop="minValue" content="0" />
                                <meta itemprop="maxValue" content="1" />
                                <meta itemprop="unitCode" content="DAY" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return <></>;
};
CollectionShippingMicrodata.propTypes = {
    page: shape({
        path: string,
    }).isRequired,
    hasMicrodata: shape({
        pagepath: oneOfType([string, bool]),
        content: oneOfType([string, bool]),
    }).isRequired,
};

export default CollectionShippingMicrodata;
